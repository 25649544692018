import React from 'react'

import styles from './SkeletonPosts.module.css'

import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

const SkeletonPosts = () => {
  return (
    <div className={styles.SkeletonPosts}>
    <div>
      <Skeleton height={50}/>
      <br/>
      <Skeleton height={400}/>
      <br/>
      <Skeleton count={5} />
    </div>
    <div>
      <Skeleton height={50}/>
      <br/>
      <Skeleton height={400}/>
      <br/>
      <Skeleton count={5} />
    </div>
    <div>
      <Skeleton height={50}/>
      <br/>
      <Skeleton height={400}/>
      <br/>
      <Skeleton count={5} />
    </div>
    <div>
      <Skeleton height={50}/>
      <br/>
      <Skeleton height={400}/>
      <br/>
      <Skeleton count={5} />
    </div>
  </div>
  )
}

export default SkeletonPosts