import React, { useEffect } from 'react'

import styles from './Alert.module.css';

const Alert = ({children, success, setAlert }) => {
  useEffect(() => {
    clearTimeout();

    // setTimeout(() => {
    //   setAlert(false)
    // }, 2000)
  }, [])

  return (
    <div className={styles.Alert} style={{ backgroundColor: success? '#508D69' : '#FF6D60'}}>
        {children}
    </div>
  )
}

export default Alert