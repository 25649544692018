import {ContentfulManagementClient} from './cms.js'

export function convertRFCToDayMonthYear(RFC) {
    let RFCstring = RFC.split("T")[0]
    const [year, month, day] = RFCstring.split("-")
    let monthKeys = {
        '01': 'JAN',
        '02': 'FEB',
        '03': 'MAR',
        '04': 'APR',
        '05': 'MAY',
        '06': 'JUN',
        '07': 'JUL',
        '08': 'AUG',
        '09': 'SEP',
        '10': 'OCT',
        '11': 'NOV',
        '12': 'DEC'
    };
    const dateString = `${monthKeys[month]} ${day}, ${year}`
    return dateString
}

export async function addNewComment(comment, author='guest', post, setAlert) {
    ContentfulManagementClient.getSpace(process.env.REACT_APP_CONTENTFUL_SPACE_ID)
    .then((space) => space.getEnvironment(process.env.REACT_APP_ENVIRONMENT_ID))
    .then((environment) => environment.createEntry('comment', {
      fields: {
        author: {
          'en-US': author
        },
        comment: {
            'en-US': comment
        },
        post: {
            'en-US': {
                sys: {
                    type: 'Link',
                    linkType: 'Entry',
                    id: post
                }
            }
        }
      }
    }))
    .then(() => setAlert(true))
    .catch(console.error)
}


// export async function getImage(assetId) {
//     await ContentfulClient.getAsset(assetId)
//     .then((asset) => {
//         console.log(asset.fields.file.url)
//         asset.fields.file.url ? asset.fields.file.url : ""
//     })
//     // .then((asset) => `${asset.fields.file.url}?fm=jpg`)
// }