import './App.css';

import { useState, useEffect } from 'react';

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import Post from './Post/Post';
import Navbar from './components/Navbar/Navbar';
import Home from './pages/Home/Home';

import { searchContext } from './contexts';

function App() {
  const [searchPhrase, setSearchPhrase] = useState('');
  const [searchLoading, setSearchLoading] = useState(false);

  useEffect(() => {
  
  }, [searchPhrase])

  return (
    <Router>
      <searchContext.Provider value={[searchPhrase, setSearchPhrase, searchLoading, setSearchLoading]}>
        <div className="App">
          <Navbar/>
          <Routes>
            <Route path="/" element={<div className="Page">
              <Home />
            </div>}/>
            <Route path="/posts/:slug" element={<div className="Page">
              <Post />
            </div>}/>
          </Routes>
          <footer>
          {/* <a href="https://www.flaticon.com/free-icons/search" title="search icons">Search icons created by Pixel perfect - Flaticon</a> */}
          </footer>
        </div>
      </searchContext.Provider>
    </Router>
  );
}

export default App;
