import React, {useEffect, useContext} from 'react';

import Posts from '../../components/Posts/Posts';

import styles from './Home.module.css';

import { searchContext } from '../../contexts.js'

import { useLocation } from 'react-router-dom';

const Home = () => {
  const [_, setSearchPhrase, ] = useContext(searchContext);

  let location = useLocation();

  useEffect(() => {
    // If you're clicking the logo after a search, then this will clear the search after you get to homepage
    if (!location.search.length > 0) {
      setSearchPhrase('')
    }
  }, [location.search])

  return (
    <div className={styles.Home}>
        <Posts/>
    </div>
  )
}

export default Home;