import {useState, useEffect, useContext} from 'react'

import { convertRFCToDayMonthYear } from '../../functions'

import styles from './Posts.module.css'

import { searchContext } from '../../contexts.js'

import SkeletonPosts from './SkeletonPosts.jsx'

import { useLocation } from 'react-router-dom'

const Posts = () => {
  const [posts, setPosts] = useState(null)
  const [postsCount, setPostsCount] = useState(0)

  const location = useLocation();

  const [searchPhrase, setSearchPhrase, searchLoading] = useContext(searchContext);

  let params = new URL(document.location).searchParams
  
  if (params.get('search')) setSearchPhrase(params.get('search'))
  
  let skip = params.get('skip') ? params.get('skip') : 0

  const query = `
  {
    blogPostCollection (where: {OR: [{title_contains: "${searchPhrase}"},{slug_contains: "${searchPhrase}"}, {category_contains_some: "${searchPhrase.charAt(0).toUpperCase() + searchPhrase.slice(1)}"}, {tags_contains_some: "${searchPhrase}"}]}, limit: 10, order: publishedDate_DESC, skip: ${skip * 10}){
      items {
        slug
        publishedDate
        title
        category 
        preview {
          json
        }
        featuredImage {
          url
        }
        tags
      }
    }
  }
`



  useEffect(() => {
    window
      .fetch(`https://graphql.contentful.com/content/v1/spaces/${process.env.REACT_APP_CONTENTFUL_SPACE_ID}/environments/${process.env.REACT_APP_ENVIRONMENT_ID}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${process.env.REACT_APP_CONTENTFUL_DELIVERY_TOKEN}`,
        },
        body: JSON.stringify({ query }),
      })
      .then((response) => response.json())
      .then(({ data, errors }) => {
        if (errors) {
          console.error(errors)
        }
        setPosts(data.blogPostCollection.items)
        setPostsCount(data.blogPostCollection.items.length)
      })
    
      window.scrollTo(0, 0)
  }, [searchPhrase, location.search]);


  if (!posts || searchLoading ) return (
    <SkeletonPosts />
  )

  return (
    <div className={styles.Posts}>
      {
            searchPhrase &&
            <div className={styles.Search}>
            <h4>Search results for: <i>'{searchPhrase}'</i></h4>
            </div>
      }
      <div className={styles.PostsContainer} >
        {
            posts && posts.map(({slug, publishedDate, title, preview, category, featuredImage, tags}, index) => (
            <div className={styles.Post} key={index}>
                <h4 className="Date">{convertRFCToDayMonthYear(publishedDate)}</h4>
                <h1 className={styles.Title}>{title}</h1>
                <small className={styles.Category}>{category}</small>
                <br/>
                <br/>
                <img src={featuredImage.url}/>
                <div className={styles.Body}>
                {
                    preview && preview.json.content.map(({content, nodeType}, index) => (
                    <div key={index}>
                        <p>
                        {
                            <div className={styles.PreviewRender}>
                           {content.map(({value, marks}, index) => (
                            <span key={index} className={`${nodeType} ${marks.length > 0 && marks.map((m) => m.type + " ")}`}>{value}</span>
                            ))}
                            <span>{index === (preview.json.content.length - 1) ? "..." : ""}</span>
                            </div>
                            
                        }
                        </p>
                    </div>
                    ))
                }
                <a href={`posts/${slug}`} className={styles.ReadMore}>
                    <button>READ MORE</button>
                </a>
                <br />
                <br/>
                <div className={styles.Tags}>
                    {
                    tags && tags.map((tag, index) => (
                        <div className={styles.Tag} key={index}>{`${tag}`}</div>
                    ))
                    }
                </div>
                <br/>
                <hr/>
                </div>
            </div>
            ))
        }
      </div>
      <div className={styles.Pagination}> 
        <button className="Gold" disabled={!skip || skip == 0} onClick={() => {
          params.set('skip', skip - 1)
          window.location.search = params
        }}>
          PREV
        </button>
        <button className="Gold" disabled={postsCount < 3} onClick={() => {
          params.set('skip', skip + 1)
          window.location.search = params
        }}>
          NEXT
        </button>
      </div>
    </div>
  )
}

export default Posts