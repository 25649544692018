import { createClient } from "contentful"
import { createClient as createClientManagement } from "contentful-management"

export const ContentfulClient = createClient({
    space: process.env.REACT_APP_CONTENTFUL_SPACE_ID,
    accessToken: process.env.REACT_APP_IS_PREVIEW === "false" ?
        process.env.REACT_APP_CONTENTFUL_PREVIEW_TOKEN :
        process.env.REACT_APP_CONTENTFUL_DELIVERY_TOKEN
})

export const ContentfulManagementClient = createClientManagement({
    accessToken: process.env.REACT_APP_CONTENTFUL_MANAGEMENT_TOKEN,
})