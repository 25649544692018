import {useState, useEffect} from 'react'

import { useParams, Link } from 'react-router-dom'

import { convertRFCToDayMonthYear, addNewComment} from '../functions';

import SkeletonPost from './SkeletonPost';

import Alert from '../components/Alert/Alert';

import styles from './Post.module.css'

const Post = () => {
  const [post, setPost] = useState(null)
  const [comments, setComments] = useState(null)
  const [newComment, setNewComment] = useState({
    comment: '',
    author: '',
    post: ''
  })
  const [commentPostSuccess, commentPostSuccessState] = useState(false)
  const [alert, setAlert] = useState(false)
  
  let { slug } = useParams()

  const query = `
  {
    blogPostCollection (where: { slug: "${slug && slug}" }, limit: 1){
      items {
        sys {
          id
        }
        slug
        category
        publishedDate
        title
        body {
          json
        }
        featuredImage {
            url
        }
        tags
        linkedFrom {
          commentCollection {
            items {
              author
              comment
            }
          }
        }
      }
    }
  }
  `

  useEffect(() => {
    window
        .fetch(`https://graphql.contentful.com/content/v1/spaces/${process.env.REACT_APP_CONTENTFUL_SPACE_ID}/`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${process.env.REACT_APP_CONTENTFUL_DELIVERY_TOKEN}`,
        },
        body: JSON.stringify({ query }),
        })
        .then((response) => response.json())
        .then(({ data, errors }) => {
        if (errors) {
            console.error(errors);
        }
        setPost(data.blogPostCollection.items[0])
        setComments(data.blogPostCollection.items[0].linkedFrom.commentCollection.items)
        
        setNewComment((prevValues) => {
          return {
            ...prevValues,
            post: data.blogPostCollection.items[0].sys.id
          }
        })
        });
  }, []);

  if (!post) return (
      <SkeletonPost />
  )

  return (
    <div className={`${styles.Post} Post`}>
        {
          alert &&
          <Alert success={commentPostSuccess} setAlert={setAlert}>
          {
            commentPostSuccess ?
            <h5>Your comment has been submitted pending approval.</h5>
            :
            <h5>Oops...something went wrong. Try again later.</h5>
          }
        </Alert>
        }
        <div className={styles.BreadCrumbs}><span><Link to="/">Home</Link></span> / <u>{post.title}</u></div>
        <h4 className='Date' style={{ marginBottom: "0.2rem" }}>{convertRFCToDayMonthYear(post.publishedDate)}</h4>
        <h1>{post.title && post.title}</h1>
        <small>{post.category}</small>
        <br/>
        <br/>
        <div className={styles.Body}>
          <img src={post.featuredImage && post.featuredImage.url} />
          {
              post.body && post.body.json.content.map(({content, nodeType}, index) => (
                <>
                  <div key={index}>
                      <span >
                      {
                          content.map(({value, marks}, index) => (
                            <p key={index} style={{  lineHeight: post.category == 'Poetry' ? '1rem' : '0.5rem' }} className={`${nodeType} ${marks.length > 0 && marks.map((m) => m.type + " ")}`}>
                              {
                                value.includes('https://images')? 
                                <img className="Embed-Img" src={value} />
                                :
                                value
                              }
                            </p> 
                          ))
                      }
                      </span>
                      {/* {
                        nodeType === 'embedded-asset-block' &&
                          getImage(data.target.sys.id)
                      } */}
                  </div>
                </>
              ))
          }
        </div>
        <div className={styles.Comments}>
          <h3 style={{ marginBottom: "0.2rem" }}>CONVERSATION</h3>
          <hr/>
          <div className={styles.NewComment}>
            <textarea placeholder="What do you think?" rows={5} value={newComment.comment} onChange={(e) => setNewComment((prevValues) => {
              return {
                ...prevValues,
                comment: e.target.value
              }
            })}/>
            <input placeholder="Your Name" value={newComment.author} onChange={(e) => setNewComment((prevValues) => {
              return {
                ...prevValues,
                author: e.target.value
              }
            })}/>
          </div>
          <div className={styles.Submit}>
            <button onClick={() => addNewComment(newComment.comment, newComment.author, newComment.post, setAlert)} className='Gold'>Submit</button>
          </div>
          {comments && comments.map(({comment, author}, index) => (
            <div key={index} className={styles.Comment}>
              <h5>{author}</h5>
              <blockquote>- {comment}</blockquote>
            </div>
          ))}
        </div>
    </div>
  )
}

export default Post