import React, { useState, useEffect, useRef, useContext } from 'react'

import { Cross as Hamburger } from 'hamburger-react'

import { Link, useLocation } from 'react-router-dom'

import { searchContext } from '../../contexts'

import styles from './Navbar.module.css'



const Navbar = () => {
  const [isOpen, setOpen] = useState(false);
  const [input, setInput] = useState('');
  // const [scroll, setScroll] = useState(false);

  let location = useLocation();

  const inputRef = useRef();

  const [_, setSearchPhrase, searchLoading, setSearchLoading] = useContext(searchContext);
 
  useEffect(() => {
    // This makes sure menu is closed whenever location changes
    setOpen(false);

  }, [location])

  // window.onscroll = function () {
  //   setScroll(true)
  // }

  // window.onscrollend = function() {
  //   setScroll(false)
  // }

  function handleSearchPhraseChange(e) {


    clearTimeout();

    setSearchLoading(true)

    const { value } = e.target;

    setInput(value);

    const TimeoutId = setTimeout(() => {
        setSearchPhrase(inputRef.current.value)
        setSearchLoading(false)
    }, 1000)

  };


  return (
    <div>
        <div className={styles.Navbar}>
            <div className={styles.NavbarContainer}>
              <Link to="/">MELANIE ROWEL</Link>
              <Hamburger toggled={isOpen} toggle={setOpen}/>
            </div>
            <div className={styles.Search} style={{ display: location.pathname === "/" ? 'block' : 'none' }}>
                <input ref={inputRef} onClick={() => setOpen(false)} type="text" placeholder="Search Posts" value={input} onChange={(e) => handleSearchPhraseChange(e)}/>
                {/* <img src="https://i.ibb.co/St2jHwZ/loupe.png" alt="loupe" width="16px" onClick={() => setSearchClickState(true)}/> */}
            </div>
        </div>
        <div className={styles.Menu} style={{ right: isOpen? '0' : '-300vw' }}>
            <nav>
                <ul>
                    <li>
                        <Link to="/about">ABOUT</Link>
                    </li>
                    <li>
                      <Link to="/?search=poetry">POETRY</Link>
                    </li>
                    <li>
                      <Link to="/?search=fiction">FICTION</Link>
                    </li>
                    <li>
                      <Link to="/?search=translations">TRANSLATIONS</Link>
                    </li>
                    <li> 
                        <Link to="/?search=photography">PHOTOGRAPHY</Link>
                    </li>
                </ul>
            </nav>
        </div>
    </div>
  )
}

export default Navbar;