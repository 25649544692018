import React from 'react'

import styles from './SkeletonPost.module.css'

import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

const SkeletonPost = () => {
  return (
    <div className={styles.SkeletonPost}>
    <div>
      <Skeleton height={20}/>
      <br/>
      <Skeleton height={50}/>
      <br/>
      <Skeleton height={400} />
      <br/>
      <Skeleton count={10} />
    </div>
  </div>
  )
}

export default SkeletonPost